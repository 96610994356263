import React from "react";
import ReactDOM from "react-dom/client";
import PropTypes from "prop-types";
import reactToWebComponent from "react-to-webcomponent";
import App from "./app.js"; // eslint-disbale-line

App.propTypes = {
  bot_id: PropTypes.string.isRequired,
  userid: PropTypes.string.isRequired,
  adminid: PropTypes.string.isRequired,
  user_image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  app_host: PropTypes.string.isRequired,
  chatbot: PropTypes.string.isRequired,
};

customElements.define(
  "react-counter",
  reactToWebComponent(App, React, ReactDOM)
);
