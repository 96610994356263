import React, { useState } from 'react';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import styles from './InputStyles.module.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const SingleRadio = ({ options, name, setCaptureFieldError, setCaptureFieldValue, template }) => {
    const [selected, setSelected] = useState('');

    const getHoverBackgroundColor = (template) => {
        console.log(template);

        const colors = {
            btc: "#3c6e95",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#090B0C",
            magicbrick: "#ffcc00",
            lundiniom: "#ffc800",
        };
        return colors[template] || "#933eff";
    };
    const getHoverBorderColor = (template) => {
        console.log(template);

        const colors = {
            btc: "#3c6e95",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#090B0C",
            magicbrick: "#ffcc00",
            lundiniom: "#ffc800",
        };
        return colors[template] || "";
    };
    const getHoverButtonColor = (template) => {
        const colors = {
            btc: "#3c6e95",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#ffffff",
            magicbrick: "#545659",
            lundiniom: "#545659",
        };
        return colors[template] || "#933eff";
    };

    return (
        // <div className={styles.inputContainer + " " + styles["input_"+template]} style={{borderColor: getHoverBorderColor(template)}    }>
        //     <FormLabel className={styles.inputLabel}>{name}</FormLabel>
        //     <RadioGroup
        //         value={selected}
        //         onChange={(e) => {
        //             setSelected(e.target.value)
        //             setCaptureFieldValue(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).replaceAll("_", " "))
        //         }}
        //         className={styles.optionGroup}
        //     >
        //         {options.map((option, index) => (
        //             <FormControlLabel
        //                 key={index}
        //                 value={option?.value}
        //                 label={option?.label}
        //                 control={
        //                     <Radio
        //                         sx={{
        //                             color: "#cbd6e2", // Default border color for unselected state
        //                             "&.Mui-checked": {
        //                                 color: getHoverBackgroundColor(template) , // Color when the radio is selected
        //                             },
        //                             '& svg': {
        //                                 fontSize: '18px', // Set the radio icon size
        //                             },
        //                         }}
        //                     />
        //                 }
        //                 sx={{
        //                     "& .MuiFormControlLabel-label": {
        //                         fontSize: "14px", // Adjust label font size here
        //                     },
        //                 }}
        //                 className={styles.radioOption}
        //             />
        //         ))}
        //     </RadioGroup>
        // </div>
        <div className={`${styles.inputContainer} ${styles[`input_${template}`]}`}>
            <FormLabel className={styles.inputLabel}>{name}</FormLabel>
            <span className={styles.subText +  " " + styles["sub_" + template]}>Choose one of the following options.</span>
            <Stack
                direction="row"
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}
            >
                {options?.map((data, index) => (
                    <span key={index} style={{ margin: "3px 2px" }}>
                        <Chip
                            label={data.label}
                            variant={selected === data.value ? "filled" : "outlined"}
                            onClick={() => {
                                setSelected(data.value)
                                setCaptureFieldValue(data.label.charAt(0).toUpperCase() + data.label.slice(1).replaceAll("_", " "))
                            }}
                            style={{
                                backgroundColor: selected === data.value
                                    ? getHoverButtonColor(template)
                                    : "",
                                borderColor: getHoverButtonColor(template),
                                color: selected===data.value
                                    ? ["daniels"].includes(template) ? "#343434" : "white"
                                    : ["daniels"].includes(template) ? "#edebeb" : "#33475b",

                            }}
                            className={styles.pill_check}
                        />
                    </span>
                ))}
            </Stack>
        </div>
    );
};

export default SingleRadio;
