import React, { useEffect, useState } from "react";
import styles from "./chatbot.module.css";
import ChatbotMessageSend from "./ChatbotMessageSend";
import { FaAngleDown, FaEllipsisV } from "react-icons/fa";
import ChatbotMessages from "./ChatbotMessages";
import Loader from "../custom/Loader";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import useChatMessages from "./hooks/useChatMessages";
import Axios from "../../utils/axios";
import { chatImg, liveIcon, logo } from "../../constants/images";

export default function Chatbot({ chatId, userid, onClose, sessionId, connectToSocket, socketNewMessage, isChatClose, userImage, adminid, name, apiBaseUrl, chatbot }) {
  const { currentChatId, hasChatCreated, createType, createChat, messages, setMessages, loading, chatLoading, setchatLoading, isBotWorking, setIsBotWorking,
    chatClose, setChatClose, handleCloseChat, ValidateKey, handleRating, handleEmojiSelect, onChatMsgSubmit, handleSendDailogApi, chatBotAdminData, isAddress, stopSendloading, range, maxRange } = useChatMessages(sessionId, apiBaseUrl, chatbot);

  const [chatId2, setChatId2] = useState("");
  const [rangeData, setRangeData] = useState(0)
  const [captureFieldError, setCaptureFieldError] = useState("");
  const [captureFieldValue, setCaptureFieldValue] = useState("");

  useEffect(() => {
    if (userid) {
      Axios.get(`${apiBaseUrl}/domainKey/viewKeyByUserName?userName=${userid}`)
        .then((res) => {
          setChatId2(res.data.data);
        })
        .catch((error) => {
          setChatId2("Nan");
        });
    } else {
      setChatId2(chatId);
    }
  }, [chatId]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => { setAnchorEl(null); };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (chatId2) {
      ValidateKey(chatId2);
    }
  }, [setMessages, chatId2]);

  useEffect(() => {
    return () => {
      handleCloseChat(chatId2)
    };
  }, []);

  useEffect(() => {
    if (currentChatId) {
      console.log("Connecting on API Response", currentChatId);
      connectToSocket(currentChatId);
    } else if (chatId) {
      console.log("Connect the socket on page load");
      const chatDataStr = localStorage.getItem(chatId);
      if (chatDataStr) {
        const chatBotConnectid = JSON.parse(chatDataStr)._id;
        connectToSocket(chatBotConnectid);
      }
    } else {
      console.log("Error count not connect the socket.");
    }
  }, [hasChatCreated]);

  useEffect(() => {
    if (socketNewMessage) {
      if (isChatClose) {
        setChatClose((prev) => isChatClose);
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        socketNewMessage.userMessage,
      ]);
    }
  }, [socketNewMessage]);

  return (
    <div className={styles.all_chatpart_design}>
      <div className={styles.all_chatpart_header}>
        <div className={styles.with_avtar}>
          <img src={userImage || chatImg} alt="" />
          {name || "Hii there !"}
        </div>
        <div className={styles.more_Option}>
          <div onClick={handleClick} className={styles.donw_arrow}>
            <FaEllipsisV />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ zIndex: 999999 }}
          >
            <Typography
              sx={{ px: 2, py: 1, cursor: "pointer" }}
              onClick={() => handleCloseChat(chatId2, onClose, handleClose)}
            >
              Close Chat
            </Typography>
          </Popover>
          <div onClick={onClose} className={styles.donw_arrow}>
            <FaAngleDown />
          </div>
        </div>
      </div>
      <div className={styles.we_online_box}>
        <img src={liveIcon} alt="" /> We are online
      </div>
      {loading ? (
        <div className={styles.all_chatpart_body}>
          <Loader size="medium" />
        </div>
      ) : (
        <ChatbotMessages
          chatId={chatId2}
          setIsBotWorking={setIsBotWorking}
          messages={messages}
          chatLoading={chatLoading}
          setchatLoading={setchatLoading}
          setMessages={setMessages}
          setChatClose={setChatClose}
          handleSendDailogApi={handleSendDailogApi}
          createChat={createChat}
          onChatMsgSubmit={onChatMsgSubmit}
          createType={createType}
          chatBotAdminData={chatBotAdminData}
          stopSendloading={stopSendloading}
          adminid={adminid}
          name={name}
          userImage={userImage}
          apiBaseUrl={apiBaseUrl}
          sessionId={sessionId}
          range={range}
          maxRange={maxRange}
          setRangeData={setRangeData}
          rangeData={rangeData}
          setCaptureFieldError={setCaptureFieldError}
          setCaptureFieldValue={setCaptureFieldValue}
        />
      )}
      <ChatbotMessageSend
        onClose={onClose}
        messages={messages}
        isBotWorking={isBotWorking}
        chatClose={chatClose}
        chatId={chatId2}
        handleRating={handleRating}
        handleEmojiSelect={handleEmojiSelect}
        onChatMsgSubmit={onChatMsgSubmit}
        handleSendDailogApi={handleSendDailogApi}
        createChat={createChat}
        isAddress={isAddress}
        stopSendloading={stopSendloading}
        apiBaseUrl={apiBaseUrl}
        createType={createType}
        range={range}
        maxRange={maxRange}
        setRangeData={setRangeData}
        rangeData={rangeData}
        captureFieldError={captureFieldError}
        captureFieldValue={captureFieldValue}
        setCaptureFieldValue={setCaptureFieldValue}
      />
      <div className={styles.powered_box}>
        Powered By <img src={logo} alt="" /> <span>Propertyjinni</span>
      </div>
    </div>
  );
}
