import React, { useState, useRef } from 'react';
import { FormLabel, Popover, Box, MenuItem, ClickAwayListener, Typography } from '@mui/material';
import styles from './InputStyles.module.css';

const DropdownMinMax = ({ minOptions, maxOptions, name, setCaptureFieldError, setCaptureFieldValue, postfix, prefix, template }) => {
  const [min, setMin] = useState(minOptions[0]?.value);
  const [max, setMax] = useState(maxOptions[0]?.value);
  const [minAnchorEl, setMinAnchorEl] = useState(null);
  const [maxAnchorEl, setMaxAnchorEl] = useState(null);
  const [error, setError] = useState('');

  const minInputRef = useRef(null);
  const maxInputRef = useRef(null);

  const handleMinClick = () => {
    setMinAnchorEl(minInputRef.current);
  };

  const handleMaxClick = () => {
    setMaxAnchorEl(maxInputRef.current);
  };

  const handleMinSelect = (value) => {
    if (parseInt(value) > parseInt(max)) {
      setError('Minimum value cannot be greater than the maximum value.');
      setCaptureFieldError(true)
    } else {
      setCaptureFieldError(false)
      setError(''); // Clear error if valid
    }
    setMin(value);
    setCaptureFieldValue((prefix ? prefix : "") + value + '-' + (prefix ? prefix : "") + max + (postfix ? " " + postfix + (parseFloat(max) > 1 ? "s" : "") : ""))
    setMinAnchorEl(null); // Close popover after selection
  };

  const handleMaxSelect = (value) => {
    if (parseInt(value) < parseInt(min)) {
      setCaptureFieldError(true)
      setError('Minimum value cannot be greater than the maximum value.');
    } else {
      setCaptureFieldError(false)
      setError(''); // Clear error if valid
    }
    setMax(value);
    setCaptureFieldValue((prefix ? prefix : "") + min + '-' + (prefix ? prefix : "") + value + (postfix ? " " + postfix + (parseFloat(value) > 1 ? "s" : "") : ""))
    setMaxAnchorEl(null); // Close popover after selection
  };

  const handleClosePopover = () => {
    setMinAnchorEl(null);
    setMaxAnchorEl(null);
  };

  const getHoverBackgroundColor = (template) => {
    const colors = {
      btc: "#3c6e95",
      talbies: "#B09981",
      charlessinclair: "#c7b354",
      denhan: "#b82142",
      daniels: "#090B0C",
      magicbrick: "#ffcc00",
      lundiniom: "#ffc800",
    };
    return colors[template] || "#933eff";
  };

  const getHoverBorderColor = (template) => {
    console.log(template);

    const colors = {
      btc: "#3c6e95",
      talbies: "#B09981",
      charlessinclair: "#c7b354",
      denhan: "#b82142",
      daniels: "#090B0C",
      magicbrick: "#ffcc00",
      lundiniom: "#ffc800",
    };
    return colors[template] || "";
  };


  return (
    <div className={styles.inputContainer + " " + styles["input_" + template]} style={{ borderColor: getHoverBorderColor(template) }}>
      <FormLabel className={styles.inputLabel}>{name}</FormLabel>
      <div className={styles.dropdownContainer }>
        {/* Min Dropdown */}
        <div className={styles.range_container}>
          <Typography variant="caption" className={styles.dropdownLabel}>Min</Typography>
          <div
            className={styles.selectInput}
            ref={minInputRef}
            onClick={handleMinClick}
            style={{ borderColor: error ? 'red' : 'inherit' }} // Apply error border if invalid
          >
            {min}
          </div>
          {/* Popover for Min Dropdown */}
          <Popover
            open={Boolean(minAnchorEl)}
            anchorEl={minAnchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{
              zIndex: 99999999,
              maxWidth: 'unset'
            }}
            className={`${styles.main_popover} ${styles["popover_" + template]}`}
          >
            <ClickAwayListener onClickAway={handleClosePopover}>
              <Box className={styles.dropdownMenu}
                style={{ maxHeight: '190px', overflowY: 'auto' }}

              >
                {minOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option?.value}
                    style={{ fontSize: '13px', fontWeight: "500", fontFamily: "Inter" }} // Set font size for the menu items
                    onClick={() => handleMinSelect(option?.value)}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </Box>
            </ClickAwayListener>
          </Popover>
        </div>

        {/* Max Dropdown */}
        <div className={styles.range_container}>
          <Typography variant="caption" className={styles.dropdownLabel}>Max</Typography>
          <div
            className={styles.selectInput}
            ref={maxInputRef}
            onClick={handleMaxClick}
            style={{ borderColor: error ? 'red' : 'inherit' }} // Apply error border if invalid
          >
            {max}
          </div>
          {/* Popover for Max Dropdown */}
          <Popover
            open={Boolean(maxAnchorEl)}
            anchorEl={maxAnchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{
              zIndex: 99999999,
              width: maxInputRef.current ? maxInputRef.current.offsetWidth : 'auto', // Match the width of the selectInput
            }}
            className={`${styles.main_popover} ${styles["popover_" + template]}`}
          >
            <ClickAwayListener onClickAway={handleClosePopover}>
              <Box className={styles.dropdownMenu}
                style={{ maxHeight: '190px', overflowY: 'auto' }}
              >
                {maxOptions?.length > 0 && maxOptions?.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option?.value}
                    style={{ fontSize: '13px', fontWeight: "500", fontFamily: "Inter" }} // Set font size for the menu items
                    onClick={() => handleMaxSelect(option?.value)}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </Box>
            </ClickAwayListener>
          </Popover>
        </div>
      </div>
      {/* Display error message if validation fails */}
      {error && (
        <Typography variant="caption" style={{ color: 'red', marginTop: '4px' }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default DropdownMinMax;
