import React, { useEffect, useState } from 'react';
import { FormLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import styles from './InputStyles.module.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const MultipleCheckbox = ({ options, name, setCaptureFieldError, setCaptureFieldValue, template }) => {
    // const [selectedOptions, setSelectedOptions] = useState([]);

    // const toggleOption = (option) => {
    //     setSelectedOptions((prev) =>
    //         prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]
    //     );
    // };


    // const getHoverBorderColor = (template) => {
    //     console.log(template);

    //     const colors = {
    //         btc: "#3c6e95",
    //         talbies: "#B09981",
    //         charlessinclair: "#c7b354",
    //         denhan: "#b82142",
    //         daniels: "#090B0C",
    //         magicbrick: "#ffcc00",
    //         lundiniom: "#ffc800",
    //     };
    //     return colors[template] || "";
    // };



    const [selectedOptions, setSelectedOptions] = useState([]);

    const toggleOption = (option) => {
        setSelectedOptions((prev) =>
            prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]
        );
    };

    const getHoverBackgroundColor = (template) => {
        const colors = {
            btc: "#3c6e95",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#090B0C",
            magicbrick: "#ffcc00",
            lundiniom: "#ffc800",
        };
        return colors[template] || "#933eff";
    };

    const getHoverButtonColor = (template) => {
        const colors = {
            btc: "#3c6e95",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#ffffff",
            magicbrick: "#545659",
            lundiniom: "#545659",
        };
        return colors[template] || "#933eff";
    };

    const getHoverBorderColor = (template) => {
        return getHoverBackgroundColor(template); // same color as hover background for border
    };

    useEffect(() => {
        const selectedOptionsString = selectedOptions?.map(option => option.charAt(0).toUpperCase() + option.slice(1).replaceAll("_", " ")).join(", ");
        setCaptureFieldValue(selectedOptionsString);
    }, [selectedOptions])

    return (
        // <div className={styles.inputContainer + " " + styles["input_" + template]} style={{ borderColor: getHoverBorderColor(template) }}>
        //     <FormLabel className={styles.inputLabel}>{name}</FormLabel>
        //     <FormGroup className={styles.optionGroup}>
        //         {options.map((option, index) => (
        //             <FormControlLabel
        //                 key={index}
        //                 control={
        //                     <Checkbox
        //                         checked={selectedOptions.includes(option?.value)}
        //                         onChange={() => toggleOption(option?.value)}
        //                         sx={{
        //                             color: "#cbd6e2", // Default border color for unselected state
        //                             "&.Mui-checked": {

        //                                 color: getHoverBackgroundColor(template), // Color when the radio is selected
        //                             },

        //                             '& svg': {
        //                                 fontSize: '18px', // Set the radio icon size
        //                             },
        //                         }}
        //                     />
        //                 }

        //                 label={option?.label}
        //                 className={styles.radioOption}

        //             />
        //         ))}
        //     </FormGroup>
        // </div>
        <div className={`${styles.inputContainer} ${styles[`input_${template}`]}`}>
            <FormLabel className={styles.inputLabel}>{name}</FormLabel>
            <span className={styles.subText +  " " + styles["sub_" + template]}>Choose one or more options</span>
            <Stack
                direction="row"
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}
            >
                {options?.map((data, index) => (
                    <span key={index} style={{ margin: "3px 2px" }}>
                        <Chip
                            label={data.label}
                            variant={selectedOptions.includes(data.value) ? "filled" : "outlined"}
                            onClick={() => toggleOption(data.value)}
                            style={{
                                backgroundColor: selectedOptions.includes(data.value)
                                    ? getHoverButtonColor(template)
                                    : "",
                                borderColor: getHoverButtonColor(template),
                                color: selectedOptions.includes(data.value)
                                    ? ["daniels"].includes(template) ? "#343434" : "white"
                                    : ["daniels"].includes(template) ? "#edebeb" : "#33475b",



                            }}
                            className={styles.pill_check}
                        />
                    </span>
                ))}
            </Stack>
        </div>
    );
};

export default MultipleCheckbox;
