export const DISTANCE_MILES_OPTIONS = [
    { label: "0 miles", milesValue: 0, Value: 0 },
    { label: "¼ mile", milesValue: 0.25, Value: 1 },
    { label: "½ mile", milesValue: 0.5, Value: 2 },
    { label: "1 mile", milesValue: 1, Value: 3 },
    { label: "2 miles", milesValue: 2, Value: 4 },
    { label: "3 miles", milesValue: 3, Value: 5 },
    { label: "4 miles", milesValue: 4, Value: 6 },
    { label: "5 miles", milesValue: 5, Value: 7 },
    { label: "7½ miles", milesValue: 7.5, Value: 8 },
    { label: "10 miles", milesValue: 10, Value: 9 },
    { label: "15 miles", milesValue: 15, Value: 10 },
    { label: "20 miles", milesValue: 20, Value: 11 },
    { label: "30 miles", milesValue: 30, Value: 12 },
    { label: "40 miles", milesValue: 40, Value: 13 }
];

export const FIELD_TYPE = [
    'single-radio',
    'multiple-checkbox',
    'dropdown-min-max',
    'dropdown-single',
    'text-min-max',
];


export const getLabelByValue = (value) => {
    const option = DISTANCE_MILES_OPTIONS.find(option => option.Value === value);
    return option ? option.label : null; // Return label if found, otherwise null
};
export const getLabelByMilesValue = (value) => {
    const option = DISTANCE_MILES_OPTIONS.find(option => option.Value === value);
    return option ? option.milesValue : null; // Return label if found, otherwise null
};
