import * as React from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import styles from "./range.module.css";
import { useEffect } from 'react';
import { getLabelByMilesValue, getLabelByValue } from '../../../constants/formFields';

function ValueLabelComponent(props) {

  const { children, value, setRangeData } = props;

  useEffect(() => {
    setRangeData(getLabelByMilesValue(value))
  }, [value])

  return (
    <div
      sx={{ zIndex: 1000 }}
      enterTouchDelay={0}
      placement="top" // Try changing this if necessary
      title={"ok"}
      className='position-relative'

    >
      <span style={{ left: `${value * 21.5 - 26}px` }} className={styles.bot_tip}>{getLabelByValue(value)}</span>

      {children}
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  setRangeData: PropTypes.func.isRequired, // PropTypes for setRangeData
};

export default function CustomizedSlider({ setRangeData }) {
  const [sliderValue, setSliderValue] = React.useState(0); // Initialize slider value to 0

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "25px" }}>
      {/* <Typography gutterBottom>Tooltip value label</Typography> */}
      <Slider
        valueLabelDisplay="auto"
        value={sliderValue} // Controlled value
        onChange={handleSliderChange} // Handle change to update state
        components={{
          ValueLabel: (props) => <ValueLabelComponent {...props} setRangeData={setRangeData} />, // Pass setRangeData
        }}
        step={1}
        marks
        min={0}
        max={13}
        aria-label="custom thumb label"
        defaultValue={0}
        sx={{ padding: "20px 0px", width: "90%" }}
      />
    </Box>
  );
}
